import React from 'react'
import Layout from "../../components/layout"
import heroObit from '../../images/new-hero.png'
import SEO from '../../components/seo'

export default function MayorWelcomes() {
    return (
        <Layout>
            <SEO title="Hero, 23" image={heroObit} />
            <h1>
                Hero, 23
            </h1>
            <img src={heroObit} />
            <p>Hero, 23, of Messina, died yesterday at her father’s home after a short-term battle with heartbreak. Born September 16, 1998, Hero was the beloved only daughter of Messina Mayor Leonato and his late wife, Ursula. May she be remembered not only for her beauty, but also for her laughter, unwavering loyalty, gentleness and innocence. </p> 

            <p>After graduating salutatorian from Messina High School, Hero attended the University of Sicily, where she majored in Hospitality.</p>

            <p>In addition to her work as an event planner, Hero was an active member of her community, dutifully serving on the Council to Beautify the Piazza.</p>

            <p>She is survived by her cousin and confidante, Beatrice, and her father.</p>

            <p>She is preceded in death by her mother.</p>
        </Layout>
    )
}
